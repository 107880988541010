import { Routes } from "@angular/router";
import { LayoutComponent } from "../layout/layout.component";

export const routes: Routes = [
  {
    path: "cart",
    loadChildren: () => import("./cart/cart.module").then(m => m.CartModule),
  },

  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
      },
      {
        path: "",
        loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule),
      },
      {
        path: "earnings-calculator",
        redirectTo: "lp/list-your-machines",
        // redirecting to list your machine in the mean time
        // loadChildren: () => import("./calculator/calculator.module").then(m => m.CalculatorModule),
      },
      {
        path: "checkout",
        loadChildren: () => import("./checkout/checkout.module").then(m => m.CheckoutModule),
      },
      {
        path: "help",
        loadChildren: () => import("./help/help.module").then(m => m.HelpModule),
      },
      {
        path: "lp",
        loadChildren: () => import("./landing-page/landing-page.module").then(m => m.LandingPageModule),
      },
      {
        path: "equipment-rental",
        loadChildren: () => import("./machine-details/machine-details.module").then(m => m.MachineDetailsModule),
      },
      {
        path: "activity",
        loadChildren: () => import("./activity/activity.module").then(m => m.ActivityModule),
      },
      {
        path: "",
        loadChildren: () => import("./search-results/search-results.module").then(m => m.SearchResultsModule),
      },
      {
        path: "",
        loadChildren: () => import("./manage/manage.module").then(m => m.ManageModule),
      },
    ],
  },

  // Not lazy-loaded routes
  // { path: 'register', component: RegisterComponent },
  // { path: 'recover', component: RecoverComponent },
  // { path: 'lock', component: LockComponent },
  // { path: 'maintenance', component: MaintenanceComponent },
  // { path: '404', component: Error404Component },
  // { path: '500', component: Error500Component },

  // Not found
  { path: "**", redirectTo: "" },
];
