import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService, EnvironmentConfig } from "../../../core/core";
import { User } from "oidc-client";
import { OverlayService } from "../../services/overlay.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { RoutesService } from "../../services/routes.service";

function flyInFromAbove(speed: string) {
  return trigger("flyInFromAbove", [transition(":enter", [style({ transform: "translateY(-100%)" }), animate(speed, style({ transform: "translateY(0)" }))])]);
}

declare let environment: EnvironmentConfig;

@Component({
  selector: "app-header-overlay",
  templateUrl: "./header-overlay.component.html",
  styleUrls: ["./header-overlay.component.scss"],
  animations: [flyInFromAbove("350ms")],
})
export class HeaderOverlayComponent implements OnInit {
  user: User;
  showOverlay = false;
  overlaySubscription: Subscription;

  menuItems: any[] = [];

  constructor(private overlayService: OverlayService, private authenticationService: AuthenticationService, public routesService: RoutesService) {
    this.authenticationService.getUser().then(user => (this.user = user));
  }

  ngOnInit() {
    this.overlayService.registerOverlay("header");
    this.overlaySubscription = this.overlayService.subscribeToOverlayUpdates("header", showOverlay => (this.showOverlay = showOverlay));

    this.menuItems = [];

    if (this.user) {
      this.menuItems = Object.assign([], this.routesService.allNavigationItems);
      this.menuItems.push(
        {
          label: "List a machine",
          route: "/owner/fleet/create",
          borderBottom: true,
        },
        {
          label: "Knowledge base",
          action: () => {
            window.location.href = "https://support.rubbl.com/";
          },
          borderBottom: true,
        },
        {
          label: "(866) 455-8531",
          action: () => {
            window.location.href = `tel:(866) 455-8531`;
          },
        },
        {
          label: "Logout",
          borderBottom: true,
          action: () => this.authenticationService.logout(),
        },
      );
    }

    if (!this.user) {
      this.menuItems.push({
        label: "Learn more",
        action: () => {
          // do nothing
        },
        borderBottom: true,
        children: [
          {
            label: "How Rubbl works",
            route: "/lp/rent-machines",
          },
          {
            label: "Renter FAQs",
            action: () => {
              window.location.href = `https://support.rubbl.com/renters`;
            },
          },
          {
            label: "Rental Terms",
            route: "/help/rental-terms",
          },
          {
            label: "Become an Owner",
            route: "/lp/list-your-machines",
          },
          {
            label: "Insurance & Protection",
            action: () => {
              window.location.href = `https://support.rubbl.com/insurance-protection`;
            },
          },
          // {
          //   label: "Earnings Calculator",
          //   route: "/earnings-calculator",
          // },

          {
            label: "Owner FAQs",
            action: () => {
              window.location.href = `https://support.rubbl.com/owners`;
            },
          },
        ],
      });

      this.menuItems.push(
        // {
        //   label: "Rubbl Connect",
        //   route: "/connect",
        //   borderBottom: true,
        //   new: true,
        // },
        {
          label: "List a machine",
          route: "/lp/list-your-machines",
          borderBottom: true,
        },
        {
          label: "(866) 455-8531",
          action: () => {
            window.location.href = `tel:(866) 455-8531`;
          },
        },
        {
          label: "Sign up",
          action: () => this.authenticationService.signUp(),
        },
        {
          label: "Login",
          borderBottom: true,
          action: () => this.authenticationService.login(),
        },
      );
    }
  }

  ngOnDestory() {
    this.overlaySubscription.unsubscribe();
  }
}
